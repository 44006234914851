import "./Alert.css"

const Alert = (props) => {
    return (
        <div id="alert">
            <div>
                {props.msg}
            </div>
        </div>
    )
}

export default Alert