import "./Dovesiamo.css"
// import locInsegna from "../img/localeFuoriInsegna.jpg"
import city from "../img/icons/city.png"
import map from "../img/icons/map.png"
import calendar from "../img/icons/calendar.png"
import clock from "../img/icons/clock.png"
import video from "../img/video_negozio.mp4"
import HelmetComp from "../components/HelmetComp"
import { useEffect } from "react"

const Dovesiamo = () => {    
    useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
    const fullscreen=()=>{
        var video = document.getElementById("video");
        if (video.requestFullscreen) {
            video.requestFullscreen();
        } else if (video.mozRequestFullScreen) { // Supporto per Firefox
            video.mozRequestFullScreen();
        } else if (video.webkitRequestFullscreen) { // Supporto per Chrome, Safari e Opera
            video.webkitRequestFullscreen();
        } else if (video.msRequestFullscreen) { // Supporto per Internet Explorer e Edge
            video.msRequestFullscreen();
        }
    }
    return (
        <div className="doveSiamo content">
            <HelmetComp title={"Il Picciotto - Dove siamo"} desc={"Vieni a trovarci!"} keys={"dove, siamo, il picciotto, saluzzo, trovarci, mappa, maps, trovare, trova, rosticceria, siciliana"}/>
            <h1 data-aos="fade-up" >Dove siamo?</h1>
            <div>
                <div className="img-video">
                    {/* <img  data-aos="zoom-out" src={locInsegna} alt="locale"/> */}
                    <video id="video" disableRemotePlayback autoPlay muted loop onClick={fullscreen}>
                        <source src={video} type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div>
                    <div className='info'>
                        <div className="subInfo">
                            <img alt="ico" className="icon black" src={city}/>
                            <span>Città:</span>
                            <span>Saluzzo</span>
                        </div>
                        <div className="subInfo">
                            <img alt="ico" className="icon black" src={map}/>
                            <span>Indirizzo:</span>
                            <span>Corso Piemonte 11/13</span>
                        </div>
                        <div className="subInfo">
                            <img alt="ico" className="icon black" src={calendar}/>
                            <span>Aperti:</span>
                            <span>7gg su 7</span>
                        </div>
                        <div className="subInfo orario">
                            <div>
                                <img alt="ico" className="icon black" src={clock}/>
                                <span>Orario:</span>
                            </div>
                            <span>
                                <ul>
                                    <li>
                                        Lunedi: 07:30 - 15:00
                                    </li>
                                    <li>
                                        Martedì: 07:30 - 21:00
                                    </li>
                                    <li>
                                        Mercoledì: 07:30 - 21:00
                                    </li>
                                    <li>
                                        Giovedì: 07:30 - 23:00
                                    </li>
                                    <li>
                                        Venerdì: 07:30 - 23:00
                                    </li>
                                    <li>
                                        Sabato: 07:30 - 23:00
                                    </li>
                                    <li>
                                        Domenica: 09:00 - 22:00
                                    </li>
                                </ul>
                            </span>
                        </div>
                    </div>
                    <a target="_blank" rel="noopener noreferrer" href="https://g.page/ilpicciottorosticceria?share">
                        <div style={{marginBottom:"35px"}} className="card">
                            <b>Vieni a trovarci!</b>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Dovesiamo