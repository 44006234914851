import React, {  useRef, useState } from 'react'
import {NavLink,useNavigate} from 'react-router-dom'
import "./Navbar.css"
import logo from "../img/logo_bianco.png"
import hamburger from "../img/icons/menu.png"
import close from "../img/icons/close.png"
import shcart from "../img/icons/cart.png"
import shcart_full from "../img/icons/cart_full.png"

const Navbar = ({cart_open,carrello,prodotti_visibili}) => {
    const menuNavbar = useRef(null)
    const [hamb, sethamb] = useState(hamburger)
    const nav=useNavigate()
    const openCloseMenu=(time=0)=>{
        if(hamb===hamburger){
            sethamb(close)
            if(prodotti_visibili)
                menuNavbar.current.style.height ="290px"
            else
                menuNavbar.current.style.height ="240px"

            menuNavbar.current.style.marginTop ="20px"
        }else{
            setTimeout(() => {
                sethamb(hamburger)
                menuNavbar.current.style.height ="0px"
                menuNavbar.current.style.marginTop ="0px"
            }, time);
        }
    }

    const openCart=()=>{
        cart_open(true)
    }

    return (
        <div className="navbar">
            <div className="navFlex">
                <div className="logo" style={{cursor:"pointer"}} onClick={()=>nav("/")}>
                    <img src={logo} alt="logo"/>
                    <div>Il Picciotto</div>
                </div>
                <div className="icons">
                    {carrello.length>0 && prodotti_visibili?
                        <img alt="ico" className="icon" src={shcart_full} style={{marginRight:"20px"}} onClick={openCart}/>:
                        prodotti_visibili?<img alt="ico" className="icon" src={shcart} style={{marginRight:"20px"}} onClick={openCart}/>:null
                    }
                    <img alt="ico" className='icon hamburger' src={hamb} onClick={openCloseMenu}/>
                </div>
            </div>
            <div className='menu' ref={menuNavbar}>
                <NavLink to="/" onClick={()=>openCloseMenu(150)}>Home</NavLink>
                <NavLink to="/dove-siamo" onClick={()=>openCloseMenu(150)}>Dove siamo</NavLink>
                <NavLink to="/offerte-notizie" onClick={()=>openCloseMenu(150)}>Offerte e notizie</NavLink>
                {prodotti_visibili?<NavLink to="/prodotti" onClick={()=>openCloseMenu(150)}>Prodotti</NavLink>:null}
                <NavLink to="/locandine" onClick={()=>openCloseMenu(150)}>Locandine</NavLink>
            </div>
        </div>
    )
}

export default Navbar
