import { useRef } from "react"
import "./Prodottocarrello.css"
import add from "../img/icons/add.png"
import remove from "../img/icons/remove_icon.png"

const Prodotto = ({dati,add_quant,rem_quant}) => {
    const q = useRef(null)
    const agg_quant=()=>{
        if(parseInt(q.current.innerHTML)<99){
            q.current.innerHTML=parseInt(q.current.innerHTML)+1
        }
        add_quant()
    }
    const rim_quant=()=>{
        if(parseInt(q.current.innerHTML)>=0){
            q.current.innerHTML=parseInt(q.current.innerHTML)-1
        }
        rem_quant()
    }
    return (
        <div className="prodottoCarrello" data-type={dati.tipo}>
            <div className="flexProd">
                <div>
                    <div className='nome'>
                        {dati.nome}
                    </div>
                    {/* <div>
                        {dati.prezzo}€
                    </div> */}
                </div>
                <div className="addRemoveButton">
                    <img alt="ico" className="icon black" src={add} onClick={agg_quant}/>
                    <span ref={q} className="quant">{dati.quant}</span>
                    <img alt="ico" className="icon black" src={remove} onClick={rim_quant}/>
                </div>               
            </div>
        </div>
    )
}

export default Prodotto
