import "./Carrello.css"
import close from "../img/icons/close.png"
import Prodottocarrello from "./Prodottocarrello"

const Carrello = ({cart_open,carrello,setcarrello,tot,setinfo_open,alrt}) => {
    const closeCart=()=>{
        cart_open(false)
    }
    const avviso=(msg,time)=>{
        alrt(msg)
        setTimeout(() => {
            alrt("")
        }, time);
    }
    const rimuovi=(prod)=>{
        var array=carrello
        array=array.filter((el)=>{
            return el.id!==prod.id
        })
        setcarrello(array)
        avviso("Rimosso dal carrello",700)
        localStorage.setItem("carrello",JSON.stringify(array))
    }
    const add_quant=(prod)=>{
        var array=carrello
        if(prod.quant<99){
            array=array.filter((el)=>{
                if (el.id===prod.id){
                    prod.quant+=1 
                }
                return prod
            })
            setcarrello(array)
            localStorage.setItem("carrello",JSON.stringify(array))
        }
    }
    const rem_quant=(prod)=>{
        var array=carrello
        if(prod.quant>1){
            array=array.filter((el)=>{
                if (el.id===prod.id){
                    prod.quant-=1 
                }
                return prod
            })
            setcarrello(array)
            localStorage.setItem("carrello",JSON.stringify(array))
        }else{
            rimuovi(prod)
        }
    }
    function svuota(){
        localStorage.setItem("carrello",JSON.stringify([]))
        setcarrello([])
    }
    return (
        <div className="popup-cont">
            <div id="cart">
                <div className="head">
                    <h2>Il tuo carrello</h2>
                    <img alt="ico" className='icon black' src={close} onClick={closeCart}/>
                </div>
                <div className="content">
                    {carrello.length===0?<h3 style={{marginTop:"50px"}}>Carrello vuoto :(</h3>:
                    carrello.map((prod)=><Prodottocarrello key={`${prod.id}${Math.random()}`} add_quant={()=>add_quant(prod)} rem_quant={()=>rem_quant(prod)} dati={prod} cart={carrello} rimuovi={()=>rimuovi(prod)}/>)}
                </div>
                {carrello.length!==0?
                    <div style={{margin:"auto",width:"90%",marginTop:"25px",display:"flex",justifyContent:"space-around",alignItems:"center"}}>
                        {/* <span>Totale: <b>{tot}€</b></span> */}
                        <input type="button" value="Conferma" onClick={()=>setinfo_open(true)}/>
                        <input type="button" value="Svuota" onClick={svuota}/>
                    </div>
                :null}
            </div>
        </div>
    )
}

export default Carrello