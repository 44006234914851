import {useState,useEffect,useRef} from 'react'
import "./Info.css"
import close_icon from "../img/icons/close.png"
import logo from "../img/logo_nero.png"

const Info = (props) => {
    const [menu, setmenu] = useState([]);
    const nome = useRef(null)
    const data = useRef(null)
    const orario = useRef(null)
    const menusel = useRef(null)
    useEffect(() => {
        setmenu([])
        scaricamenu()
    }, []);
    var today = new Date().toJSON().slice(0,10)
    const close=()=>{
        props.setinfo_open(false)
    }
    const scaricamenu=()=>{
        let link="https://www.ilpicciotto.it/endpoints/getProdotti.php"
        fetch(link)
        .then(response=>response.json())
        .then(data=>{
            var contaProdotti=0
            var contaBevande=0
            var contaArancine=0
            props.carrello.forEach((el)=>{
                let nome=el.nome.toLowerCase()
                if(el.tipo!=="bevanda"){
                    contaProdotti+=el.quant
                }else{
                    contaBevande+=el.quant
                }
                if(nome.includes("arancina")){
                    contaArancine+=el.quant
                }
            })
            // console.log(contaArancine,contaBevande,contaProdotti)
                if(contaProdotti<=3 && contaProdotti>=1 && contaBevande===1){
                    switch(contaProdotti){
                        case 2:
                            setmenu(menu=>[...menu,<option data-costo={7.5}>Menù 2 pz {parseFloat(7.5+0.5*contaArancine)}€</option>])
                            break;
                        case 3:
                            setmenu(menu=>[...menu,<option data-costo={8.5}>Menù 3 pz {parseFloat(8.5+0.5*contaArancine)}€</option>])
                            break;
                    }
                }else{
                    setmenu([])
                }
            })
    }
    const check=()=>{
        var ok=true
        if(nome.current.value===""){
            ok=false
        }
        if(data.current.value===""){
            ok=false
        }
        if(orario.current.value===""){
            ok=false
        }
        return ok
    }
    const avanti=()=>{
        let ok=check()
        // ok=true //testing
        if(ok){
            if(menusel.current.selectedIndex!==0){
                props.settotale(menusel.current.options[menusel.current.selectedIndex].dataset.costo)
            }
            props.setordine({nome:nome.current.value,data:data.current.value,orario:orario.current.value,menu:menusel.current.options[menusel.current.selectedIndex].value})
            close()
            props.setscont_open(true)
        }else{
            alert("Dati mancanti")
        }
    }
    return (
        <div className='infoscreen'>
            <img alt="ico" className='icon chiudi black' src={close_icon} onClick={close}/>
            <img alt="logo" className='logo' style={{width:"30%"}} src={logo}/>
            <h2>Dati della prenotazione</h2>
            <div className='boxes'>
                <div className='inputbox'>
                    <b>Nome:</b>
                    <input type="text" ref={nome} placeholder="es. Mario Rossi"/>
                </div>
                <div className='inputbox'>
                    <b>Giorno:</b>
                    <input type="date" ref={data} min={today}/>
                </div>
                <div className='inputbox'>
                    <b>Orario:</b>
                    <input type="time" ref={orario}/>
                </div>
                <div className='inputbox'>
                    <b>Menù:</b>
                    <select ref={menusel}>
                        <option>Nessuno</option>
                        {menu}
                    </select>
                </div>
            </div>
            <input type="button" value="Avanti" onClick={avanti}/>
        </div>
    )
}

export default Info
