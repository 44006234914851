import React from 'react'
import "./Prodotto.css"
import add from "../img/icons/add.png"

const Prodotto = ({dati,aggiungi}) => {
    return (
        <div className="prodotto" data-type={dati.tipo}>
            <div className="flexProd">
                <div>
                    <div className='nome'>
                        {dati.nome}
                    </div>
                    <div data-aos="flip-up">
                        {dati.disponibile!==""?`[Disponibile ${dati.disponibile}]`:`${dati.prezzo}€`}
                    </div>
                    <div style={{marginTop:"20px",width:"90%"}}>
                        {dati.descrizione}
                    </div>
                </div>
                <div className="addRemoveButton">
                    {aggiungi!==undefined && dati.disponibile===""?<img alt="ico" className="icon black" src={add} onClick={aggiungi}/>:null}
                </div>               
            </div>
        </div>
    )
}

export default Prodotto
