import {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
    const nav = useNavigate();

    useEffect(() => {
        nav('/');
    }, []);

    return null;
};

export default NotFound;