import right from "../img/icons/right.png"
import giu from "../img/icons/down.png"
import "./CategoriaProd.css"

const CategoriaProd = ({img_icon,title,id,prods,nota,inner}) => {
    const apri=(e)=>{
        let el=e.target
        if(document.querySelector("#"+el.name).classList.contains("close")){
            document.querySelector("#"+el.name).classList.remove("close")
            el.src=giu
        }else{
            document.querySelector("#"+el.name).classList.add("close")
            el.src=right
        }
    }
    return (
    <>
        <div className={inner?"dropdown inner":"dropdown"}>
        <div className="item">
            <img alt="ico" src={img_icon} className="icon black"/>
            <h2>{title}</h2>
        </div>
        <img alt="ico" src={right} className="icon black" name={id} onClick={apri}/>
        </div>
        <div className={inner?"droplist inner close":"droplist close"} id={id}>
            {prods}
            {nota?<span style={{padding:"10px",fontSize:"18px"}}>{nota}</span>:null}
        </div>
    </>
    )
}

export default CategoriaProd