import "./Contattaci.css"
import ws from "../img/icons/ws.png"
import HelmetComp from "../components/HelmetComp"
import { useEffect } from "react"

const Contattaci = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const sendMail=()=>{
        let nome=document.getElementById("lname").value
        let cognome=document.getElementById("fname").value
        let soggetto=nome+" "+cognome
        let testo=document.getElementById("text").value
        window.open(`mailto:ilpicciottoofficial@gmail.com?subject=${soggetto}&body=${testo}`)
    }
    return (
        <div className="contattaci content">
            <HelmetComp title={"Il Picciotto - Lavora con noi"} desc={"Vuoi essere assunto al Picciotto? Inviaci subito il tuo CV!"} keys={"lavoro, il picciotto, saluzzo, contattaci, cv,  curriculum, cerco, lavora con noi, lavora, lavorare"}/>
            <h1 data-aos="fade-up" >Lavora con noi</h1>
            <div className="infoButton">
                <div className="subContatto">
                    <span className="subContattoDesc">Numero di telefono:</span>
                    <span>+39 346 967 1578</span>
                </div>
                <div className="contactButton">
                    <a href="https://wa.me/393469671578">
                        <div>
                            <img alt="ico" src={ws} className="icon black"/>
                        </div>
                        <div>
                            Invia il CV per messaggio    
                        </div>
                    </a>               
                </div>
            </div>
            <form>
                <h3>Invia il tuo curriculum per email</h3>
                <label for="fname">Nome:</label>
                <input type="text" id="fname" name="fname" placeholder="Es. Mario"/>
                <label for="lname">Cognome:</label>
                <input type="text" id="lname" name="lname" placeholder="Es. Rossi"/>
                <label for="lname">Testo:</label>
                <input type="text" id="text" name="text" placeholder="Testo da inviare"/>
                <input type="button" name="bho" value="Invia" onClick={sendMail}/>
            </form>
        </div>
    )
}

export default Contattaci
