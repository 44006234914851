import "./Offerte.css"
import PopupSocial from "../components/PopupSocial";
import LocandineViewer from "../components/LocandineViewer";
import LocandineSpecialiViewer from "../components/LocandineSpecialiViewer";
import { useEffect } from "react";
import Allergeni_Button from "../components/Allergeni_Button";

const Locandine = ({speciali}) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="offerteNotizie content">
            <PopupSocial/>
            <Allergeni_Button/>
            {speciali?<LocandineSpecialiViewer/>:<LocandineViewer/>}
        </div>
    )
}

export default Locandine
