import { Link } from "react-router-dom"
import "./HomeCard.css"

const HomeCard = ({title,desc,link,buttonTitle}) => {
    return (
        <div className='card' data-aos="fade-up" >
            <h2>{title}</h2>
            <p>{desc}</p>
            <Link to={link}>{buttonTitle}</Link>
        </div>
    )
}

export default HomeCard