import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import NotFound from './components/NotFound';
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Alert from "./components/Alert";
import Carrello from "./components/Carrello";
import Scontrino from "./components/Scontrino";
import Info from "./components/Info";
import Home from "./pages/Home";
import Dovesiamo from "./pages/Dovesiamo";
import Offerte from "./pages/Offerte";
import Prodotti from "./pages/Prodotti";
import Termini from "./pages/Termini";
import Contattaci from './pages/Contattaci';
import Lavoraconnoi from './pages/Lavoraconnoi';
import Locandine from './pages/Locandine';
import SiteStatistics from './components/SiteStatistics';

const Main = () => {
	const [cart_open, setcart_open] = useState(false);
	const [scont_open, setscont_open] = useState(false);
	const [info_open, setinfo_open] = useState(false);
	const [locandine_speciali, setlocandine_speciali] = useState(false);
	const [menu_visibile, setmenu_visibile] = useState(true);
	const [carrello, setcarrello] = useState([]);
	const [totale, settotale] = useState(0);
	const [alertmsg, setalertmsg] = useState("");
	const [ordine, setordine] = useState({});

	useEffect(() => {
		if(localStorage.getItem("carrello")){
            setcarrello(JSON.parse(localStorage.getItem("carrello")))
        }
		AOS.init();
		contaVisitatore()
		getConfig();
	}, []);

	useEffect(() => {
		calcola_tot()
	}, [carrello]);
	
	const getConfig=()=>{
		let link="https://www.ilpicciotto.it/endpoints/getConfig.php"
		fetch(link)
		.then(response=>response.json())
		.then(data=>{
			setlocandine_speciali(data.locandine_speciali);
			setmenu_visibile(data.menu_visibile);
		})
	}

	function calcola_tot(){
		var t=0
		carrello.forEach((prod)=>{
			t+=prod.prezzo*prod.quant
		})
        settotale(t)
	}
	async function contaVisitatore(){
		try {
			await fetch('https://www.ilpicciotto.it/endpoints/countStats.php');
		} catch (error) {
			console.error('Errore durante il recupero delle statistiche:', error);
		}
	}

	return (
		<Router>
			<div className="main">
				<Navbar cart_open={setcart_open} carrello={carrello} prodotti_visibili={menu_visibile} />
				{cart_open ? (
				<Carrello
					alrt={setalertmsg}
					cart_open={setcart_open}
					setinfo_open={setinfo_open}
					setcarrello={setcarrello}
					settot={settotale}
					carrello={carrello}
					tot={totale}
				/>
				) : null}
				{alertmsg !== "" ? <Alert msg={alertmsg} /> : null}
				{info_open ? (
				<Info
					setordine={setordine}
					carrello={carrello}
					setscont_open={setscont_open}
					setinfo_open={setinfo_open}
					settotale={settotale}
				/>
				) : null}
				{scont_open ? (
				<Scontrino
					carrello={carrello}
					ordine={ordine}
					setscont_open={setscont_open}
					totale={totale}
				/>
				) : null}
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/dove-siamo" element={<Dovesiamo />} />
					<Route path="/offerte-notizie" element={<Offerte />} />
					<Route path="/locandine" element={<Locandine speciali={locandine_speciali} />} />
					<Route
						path="/prodotti"
						element={
						menu_visibile?<Prodotti
							alrt={setalertmsg}
							tot={totale}
							settot={settotale}
							cart={{ carrello, setcarrello }}
							visibili={menu_visibile}
						/>:<Locandine speciali={locandine_speciali} />
						}
					/>
					<Route path="/contatti" element={<Contattaci />} />
					<Route path="/lavora-con-noi" element={<Lavoraconnoi />} />
					<Route path="/statistiche-sito" element={<SiteStatistics />} />
					<Route path="/termini-e-condizioni" element={<Termini />} />
					<Route path="*" element={<NotFound />} />
				</Routes>
				<Footer />
			</div>
		</Router>
	);
}

export default Main;
