import "./InfoLegali.css"
const InfoLegali = () => {
    return (
        <div id="info-legal">
            <div>Rag.Soc. IL PICCIOTTO di Rera Davide</div>
            <div>Sede: Corso Piemonte 11, Saluzzo (CN)</div>
            <div>P.IVA: 03737250047</div>
            <div>C.F. RRE DVD 95D 12G 273K</div>
        </div>
    )
}

export default InfoLegali