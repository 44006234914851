import "./PopupSocial.css"
import ig from "../img/icons/ig.png"
import fb from "../img/icons/fb.png"
import close from "../img/icons/close.png"
import { useRef } from "react"

const PopupSocial = () => {
	const pop = useRef(null)
	const chiudi=()=>{
		pop.current.classList.add("close")
	}
    return (
        <div id="popup-cont" ref={pop}>
			<div id="popup-social">
				<img src={close} alt='icona_chiudi' onClick={chiudi} className="icon black chiudi"/>
				<h5>Seguici sui social!</h5>
				<div className='social'>
					<a target="_blank" onClick={chiudi} rel="noopener noreferrer" href="https://www.instagram.com/ilpicciottorosticceria/?hl=it">
						<img src={ig} alt='icona_instagram' className="icon black"/><span>@ilpicciottorosticceria</span>
					</a>
					<a target="_blank" onClick={chiudi} rel="noopener noreferrer" href="https://www.facebook.com/Il-Picciotto-Rosticceria-Siciliana-262939167571410">
						<img src={fb} alt='icona_facebook' className="icon black"/><span>Il Picciotto - Rosticceria Siciliana</span>
					</a>
                </div>
			</div>
		</div>
    )
}

export default PopupSocial