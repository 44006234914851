import "./Offerte.css"
import logo from "../img/logo_nero.png"
import ig from "../img/icons/ig.png"
import fb from "../img/icons/fb.png"
import HelmetComp from "../components/HelmetComp"
import { useEffect } from 'react'
// import Offerta from "../components/Offerta"

const Offerte = () => {
    useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
    // const divOfferte = useRef(null)
    // const divNotizie = useRef(null)
    // useEffect(() => {
    //     caricaOfferte()
    // }, [])
    // const [offerte, setofferte] = useState([])
    // const [notizie, setnotizie] = useState([])
    // const caricaOfferte=()=>{
    //     let link="https://raw.githubusercontent.com/alessiorera/ilPicciottoJson/main/offerte-notizie.json"
    //     fetch(link)
    //     .then(response=>response.json())
    //     .then(data=>{
    //         console.log(data.notizie[0].url)
    //         if(data.offerte.length===0){
    //             divOfferte.current.style.display="none"
    //         }else{
    //             data.offerte.forEach(offerta=>{
    //                 setofferte(offerte=>[...offerte,<Offerta key={offerta.nome} nome={offerta.nome} url={offerta.url}/>])
    //             })
    //         }
    //         if(data.notizie.length===0){
    //             divNotizie.current.style.display="none"
    //         }else{
    //             data.notizie.forEach(notizia=>{
    //                 setnotizie(notizie=>[...notizie,<Offerta key={notizia.nome} nome={notizia.nome} url={notizia.url}/>])
    //             })
    //         }
    //     })
    // }
    return (
        <div className="offerteNotizie content">
            <HelmetComp title={"Il Picciotto - Offerte e notizie"} desc={"Qui verrai aggiornato sulle ultime offerte e notizie del Picciotto!"} keys={"offerte, notizie, promozione, promozioni, sconto, sconti, il picciotto, saluzzo, picciotto, rosticceria, siciliana"}/>
            {/* <p data-aos="fade-up" >Qui verrai aggiornato sulle ultime <b>offerte e notizie</b> del Picciotto!</p> */}
            {/* <div>
                <div className="offerte" ref={divOfferte}>
                    <h1 data-aos="fade-up" >Ultime offerte:</h1>
                    <div className='offerteDiv'>
                        {offerte.length!==0?offerte:<h2 style={{textAlign:"center",marginTop:"50px"}}>Nessuna nuova offerta :(</h2>}
                    </div>
                </div>
                <div className="notizie" ref={divNotizie}>
                    <h1 data-aos="fade-up" >Ultime notizie:</h1>
                    <div className='notizieDiv'>
                        {notizie.length!==0?notizie:<h2 style={{textAlign:"center",marginTop:"50px",marginBottom:"0"}}>Nessuna nuova notizia :(</h2>}
                    </div>
                </div>
            </div> */}
            <div id="social">
                <img src={logo} alt="logo" className="logo"/>
                <h3>Seguici sui social per rimanere aggiornato</h3>
                <div className='social'>
                    <a target="_blank" rel="noopener noreferrer" id="ig" href="https://www.instagram.com/ilpicciottorosticceria/?hl=it"><img src={ig} alt='icona_instagram' className="icon black"/></a>
                    <a target="_blank" rel="noopener noreferrer" id="fb" href="https://www.facebook.com/Il-Picciotto-Rosticceria-Siciliana-262939167571410"><img src={fb} alt='icona_facebook' className="icon black"/></a>
                </div>
            </div>
        </div>
    )
}

export default Offerte
