import { Helmet } from 'react-helmet';

const HelmetComp = ({title,desc,keys}) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={desc} />
            <meta name="keywords" content={keys} />
        </Helmet>
    )
}

export default HelmetComp