import React, { useState, useEffect } from 'react';

const SiteStatistics = () => {
    const [statistics, setStatistics] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
                const response = await fetch('https://www.ilpicciotto.it/endpoints/getStats.php');
                const data = await response.json();
                setStatistics(data);
            } catch (error) {
                console.error('Errore durante il recupero delle statistiche:', error);
            }
        };
        const formatDateItalian = (dateString) => {
            const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
            const date = new Date(dateString);
            return date.toLocaleDateString('it-IT', options);
        };
    return (
        <div style={{padding:50,display:"flex",flexDirection:"column",gap:30}}>
        <h2>Statistiche del Sito</h2>
        <div>
        {statistics.map((giorno) =>(
            <div style={{backgroundColor:"rgb(255,255,255)",borderRadius:20,display:"flex",flexDirection:"column",gap:10,padding:20,marginBottom:20}} key={giorno.data}>
                <p style={{margin:0,padding:0}}><b>Data:</b> {formatDateItalian(giorno.data)}</p>
                <p style={{margin:0,padding:0}}><b>Visite:</b> {giorno.visite}</p>
            </div>
            )
        )}
        </div>
        </div>
    );
};

export default SiteStatistics;
