import "./Recensione.css"
// import vuota from "../img/icons/stars/vuota.png"
// import mezza from "../img/icons/stars/mezza.png"
import piena from "../img/icons/stars/piena.png"
const Recensione = ({logo1,link1,logo2,link2,logo3,link3}) => {
    return (
        <div id="recensione">
            <div id="stars">
                <img className="star full" src={piena} alt="star"/>
                <img className="star full" src={piena} alt="star"/>
                <img className="star full" src={piena} alt="star"/>
                <img className="star full" src={piena} alt="star"/>
                <img className="star full" src={piena} alt="star"/>
            </div>
            <div id="links">
                <a href={link1} target="_blank" rel="noreferrer noopener">
                    <img src={logo1} className="logo-rec" alt="logo"/>
                </a>
                <a href={link2} target="_blank" rel="noreferrer noopener">
                    <img src={logo2} className="logo-rec" alt="logo"/>
                </a>
                <a href={link3} target="_blank" rel="noreferrer noopener">
                    <img src={logo3} className="logo-rec" alt="logo"/>
                </a>
            </div>
        </div>
    )
}

export default Recensione