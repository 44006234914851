import "./Contattaci.css"
import ws from "../img/icons/ws.png"
import phone from "../img/icons/phone.png"
import map from "../img/icons/map.png"
import HelmetComp from "../components/HelmetComp"
import { useEffect } from "react"

const Contattaci = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    
    const sendMail=()=>{
        let nome=document.getElementById("lname").value
        let cognome=document.getElementById("fname").value
        let soggetto=nome+" "+cognome
        let testo=document.getElementById("text").value
        window.open(`mailto:ilpicciottoofficial@gmail.com?subject=${soggetto}&body=${testo}`)
    }
    return (
        <div className="contattaci content">
            <HelmetComp title={"Il Picciotto - Contattaci"} desc={"Contatta lo staff del Picciotto per informazioni!"} keys={"contatti, il picciotto, saluzzo, contattaci, numero, email, telefono"}/>
            <h1 data-aos="fade-up" >Contattaci</h1>
            <div className="infoButton">
                <div className="subContatto">
                    <span className="subContattoDesc">Numero di telefono:</span>
                    <span>+39 346 967 1578</span>
                </div>
                <div style={{margin:"auto",backgroundColor:"white",width:"90%",display:"flex",justifyContent:"center",padding:"20px",marginBottom:"20px",marginTop:"-20px"}}>
                    <a style={{display:"flex",alignItems:"center",gap:"5px"}} className="chiamaButton" href="/dove-siamo">
                        <img alt="ico" src={map} className="icon black"/>
                        <div>
                            Dove siamo                  
                        </div>
                    </a>
                </div>
                <div className="contactButton">
                    <a className="chiamaButton" href="tel:3469671578">
                        <div>
                            <img alt="ico" src={phone} className="icon black"/>
                        </div>
                        <div>
                            Chiama ora                   
                        </div>
                    </a>
                    <a href="https://wa.me/393469671578">
                        <div>
                            <img alt="ico" src={ws} className="icon black"/>
                        </div>
                        <div>
                            Invia un messaggio    
                        </div>
                    </a>               
                </div>
            </div>
            <form>
                <h3>Contattaci per feedback sul sito</h3>
                <label for="fname">Nome:</label>
                <input type="text" id="fname" name="fname" placeholder="Es. Mario"/>
                <label for="lname">Cognome:</label>
                <input type="text" id="lname" name="lname" placeholder="Es. Rossi"/>
                <label for="lname">Testo:</label>
                <input type="text" id="text" name="text" placeholder="Testo da inviare"/>
                <input type="button" name="bho" value="Invia" onClick={sendMail}/>
            </form>
        </div>
    )
}

export default Contattaci
