import Recensione from "../components/Recensione"
import logo from "../img/logo_nero.png"
import banner from "../img/banner.png"
import video1 from "../img/video_ad.mp4"
import video2 from "../img/video_arancine.mp4"
import "./Home.css"
import trip from "../img/icons/trip.png"
import fb from "../img/icons/fb_colored.png"
import google from "../img/icons/google.png"
import HomeCard from '../components/HomeCard'
import HelmetComp from "../components/HelmetComp"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const Home = () => {
    return (
        <div className="home content">
            <HelmetComp title={"Il Picciotto - Home"} desc={"Benvenuto nel sito ufficiale del Picciotto Rosticceria siciliana! Qui potrai trovare informazioni su di noi, sul nostro menu, i nostri orari e potrai addirittura ordinare i nostri prodotti! Vieni anche a trovarci a Saluzzo!"} keys={"rosticceria, siciliana, picciotto, il picciotto, saluzzo"}/>
            <div className="video-cont">
                <img data-aos="flip-up" className="banner" src={banner} alt="banner"/>        
            </div>
            <div id="flex-home">
                <div>
                    <div className='logoGrande'>
                        <img data-aos="flip-up" src={logo} alt="logo"/>
                    </div>
                    <Recensione logo1={trip} logo2={fb} logo3={google} link1="https://www.tripadvisor.com/Restaurant_Review-g194893-d13481679-Reviews-Il_Picciotto_Rosticceria_Siciliana_Pizza_al_taglio-Saluzzo_Province_of_Cuneo_Pie.html" link2="https://www.facebook.com/profile.php?id=100063470472974" link3="https://g.co/kgs/Ua5dDd"/>
                </div>
                <div className="video-cont">
                    <Carousel showThumbs={false} showStatus={false} showArrows={false}>
                        <video disableRemotePlayback autoPlay muted loop>
                        <source src={video1} type="video/mp4"/>
                            Your browser does not support the video tag.
                        </video>
                        <video disableRemotePlayback autoPlay muted loop>
                            <source src={video2} type="video/mp4"/>
                            Your browser does not support the video tag.
                        </video>
                    </Carousel>
                </div>
            </div>
            <section data-aos="fade-up" >
                <HomeCard title="Fame? ci pensiamo noi" desc="Sfoglia il nostro menu' online e ordina direttamente da qui!" link="/prodotti" buttonTitle="Clicca qui" />
                <HomeCard title="Sicilia a 2 passi da casa" desc="La rosticceria siciliana in Piemonte. Cosa aspetti? Vieni a trovarci" link="/dove-siamo" buttonTitle="Dove siamo" />
                <HomeCard title="Lavora con noi" desc="Vuoi lavorare con noi? Inviaci adesso il tuo CV online!" link="/lavora-con-noi" buttonTitle="Clicca qui" />
                <HomeCard title="Sempre a vostra disposizione" desc="Contattaci per ordinare o per informazioni" link="/contatti" buttonTitle="Contattaci" />
            </section>
        </div>
    )
}

export default Home
