import "./Prodotti.css"
import { useEffect, useState } from "react"
import pizza from "../img/icons/pizza.png"
import arancina from "../img/icons/arancina.png"
import cookie from "../img/icons/cookie.png"
import bevande_icon from "../img/icons/bevande.png"
import menu_icon from "../img/icons/menu_book.png"
import menu_risto from "../img/icons/menu_ristorante.png"
import Prodotto from "../components/Prodotto"
import BannerDiscount from "../components/BannerDiscount"
import HelmetComp from "../components/HelmetComp"
import CategoriaProd from "../components/CategoriaProd"
import LocandineViewer from "../components/LocandineViewer"
import LocandineMenuViewer from "../components/LocandineMenuViewer"
import Allergeni_Button from "../components/Allergeni_Button"
import { PhotoProvider } from "react-photo-view"

var carrello
var setcarrello
const Prodotti = ({cart,alrt,tot,settot}) => {
    carrello=cart.carrello
    setcarrello=cart.setcarrello
    const [salati, setsalati] = useState([])
    const [dolci, setdolci] = useState([])
    const [menu, setmenu] = useState([])
    const [bevande, setbevande] = useState([])

    useEffect(() => {
        window.scrollTo(0, 0);
        setsalati([])
        setdolci([])
        setbevande([])
        setmenu([])
        caricaProdotti()
    }, [])

    const avviso=(msg,time)=>{
        alrt(msg)
        setTimeout(() => {
            alrt("")
        }, time);
    }
    const check=(prod)=>{
        var array=carrello
        var presente=false
        array=array.filter((el)=>{
            if(el.id===prod.id){
                presente=true
                el.quant+=1 
            }
            return prod
        })
        setcarrello(array)
        return presente
    }
    const aggiungi=(prod)=>{
        var obj=carrello
        let ce=check(prod)
        if(!ce){
            prod.quant=1
            obj.push(prod)
            setcarrello(cart=>[...cart,prod])
        }
        avviso("Aggiunto al carrello",700)
        //update
        localStorage.setItem("carrello",JSON.stringify(obj))
    }
    const caricaProdotti=()=>{
        let link="https://www.ilpicciotto.it/endpoints/getProdotti.php"
        fetch(link)
        .then(response=>response.json())
        .then(data=>{
            var arrayCibiSalati=[]
            var arrayPizze=[]
            var arrayArancine=[]
            var arrayDolci=[]
            var arrayMenu=[]
            var arrayBevande=[]
            data.cibi.salato.forEach(prod=>{
                let nm=prod.nome.toLowerCase()
                if(nm.includes("arancina")){
                    arrayArancine.push(<Prodotto key={prod.id} dati={prod} aggiungi={()=>aggiungi(prod)}/>)
                }else if(nm.includes("pizza")){
                    arrayPizze.push(<Prodotto key={prod.id} dati={prod} aggiungi={()=>aggiungi(prod)}/>)
                }else{
                    arrayCibiSalati.push(<Prodotto key={prod.id} dati={prod} aggiungi={()=>aggiungi(prod)}/>)
                }
            })
            let dropAran=
            <CategoriaProd inner={true} title="Arancine" img_icon={arancina} id="arancine" prods={arrayArancine}/>
            let dropPizz=
            <CategoriaProd inner={true} title="Pizze" img_icon={pizza} id="pizze" prods={arrayPizze}/>
            setsalati([dropAran,dropPizz,...arrayCibiSalati])
            data.cibi.dolce.forEach(prod=>{
                arrayDolci.push(<Prodotto key={prod.id} dati={prod} aggiungi={()=>aggiungi(prod)}/>)
            })
            setdolci(arrayDolci)
            data.bevande.forEach(prod=>{
                prod.tipo="bevanda"
                arrayBevande.push(<Prodotto key={prod.id} dati={prod} aggiungi={()=>aggiungi(prod)}/>)
            })
            setbevande(arrayBevande)
            // data.menu.forEach(prod=>{
            //     arrayMenu.push(<Prodotto key={prod.id} dati={prod}/>)
            // })
            // setmenu(arrayMenu)
        })
    }
    return (
        <div className="prodotti content">
            <HelmetComp title={"Il Picciotto - i nostri prodotti"} desc={"Scopri il nostro menù pieno di tipiche prelibatezze siciliane!"} keys={"prodotti, menu, rosticceria, siciliana, picciotto, il picciotto, saluzzo, elenco, arancina, arancine, calzone, calzoni, cannolo, cannoli"}/>
            <h1 data-aos="fade-up" >Sfoglia il menu e ordina</h1>
            <h5 style={{margin:"auto",textAlign:"center",padding:"20px",marginBottom:"20px",marginTop:"-40px"}}>Componi il menù e invia l'ordine su Whatsapp, qui non dovrai pagare nulla, verrà confermato l'ordine una volta ricevuta risposta per messaggio</h5>
            {/* <BannerDiscount/> */}
            <div id="categories">
                <CategoriaProd title="Salati" img_icon={pizza} id="salati" prods={salati}/>
                <CategoriaProd title="Dolci" img_icon={cookie} id="dolci" prods={dolci}/>
                <CategoriaProd title="Bevande" img_icon={bevande_icon} id="bevande" prods={bevande}/>
                <CategoriaProd title="Menù risparmio" img_icon={menu_icon} id="menu" prods={
                    <PhotoProvider>
                        <div style={{display:"flex",padding:"30px",flexWrap:"wrap",gap:"20px",justifyItems:"center",justifyContent:"center"}}>
                            <LocandineMenuViewer/>
                        </div>
                    </PhotoProvider>}/>
                <CategoriaProd title="Menù sul posto" img_icon={menu_risto}  id="menuposto" prods={<LocandineViewer/>}/>
            </div>
            <Allergeni_Button/>
            <br/>
        </div>
    )
}

export default Prodotti