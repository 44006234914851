import { PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

const LocandineMenuViewer = () => {
    var imgs=["1","2"];
    return (
        imgs.map((link,i)=>{
            return (
                <PhotoView key={i} src={require(`../img/locandine/${link}.jpeg`)}>
                    <img data-aos="flip-left" data-aos-duration="500" style={{width:"min(300px,90%)",borderRadius:"10px",boxShadow:"3px 3px 5px rgba(0,0,0,0.3)"}} alt="" src={require(`../img/locandine/${link}.jpeg`)} />
                </PhotoView>
            )
        })
    )
}

export default LocandineMenuViewer