import React from 'react'
import "./Footer.css"
import logo from "../img/logo_bianco.png"
import ig from "../img/icons/ig.png"
import fb from "../img/icons/fb.png"
import Banner from './Banner'
import InfoLegali from './InfoLegali'

const Footer = () => {
    return (
        <div className="footer">
                <div className="logo">
                    <img src={logo} alt="logo"/>
                    <div>Il Picciotto</div>
                </div>
                <div className='social'>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/ilpicciottorosticceria/?hl=it"><img alt='icona_instagram' src={ig} className="icon"/></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/Il-Picciotto-Rosticceria-Siciliana-262939167571410"><img alt='icona_facebook' src={fb} className="icon"/></a>
                </div>
                <div className='info'>
                    <div>
                        <a href="/dove-siamo">Dove siamo</a>
                        <a href="/contatti">Contattaci</a>
                        <a href="/termini-e-condizioni">Termini e condizioni</a>
                    </div>
                </div>
                <InfoLegali/>
                <Banner/>
        </div>
    )
}

export default Footer
