import { Link } from 'react-router-dom'
import "./Allergeni_Button.css"

const Allergeni_Button = () => {
    return (
        <div id="allerg_btn">
            <a href="https://www.ilpicciotto.it/LIBRO-UNICO-ALLERGENI-PICCIOTTO-elda-srl.pdf">
                Scarica il libro ingredienti e allergeni
            </a>
        </div>
    )
}

export default Allergeni_Button